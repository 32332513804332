.publications__events {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 140vh;
  background-color: var(--light_gray);
}
.publications__events__container {
  display: flex;
  align-items: center;
  min-height: 135vh;
  justify-content: center;
  width: 100%;
}
.publications__events__gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 126vh;
  width: 64%;
  background-color: var(--white);
}
.publications__event__Img {
  display: flex;
  margin: 3px;
  width: 31vw;
  height: 62vh;
}
.publications__event__Img img {
  width: 100%;
  height: 100%;
}
.publications__events__calendar {
  display: flex;
  width: 28%;
  height: 80vh;
  background-color: var(--white);
  flex-direction: column;
}
.publications__events__cal__header {
  display: flex;
  width: 100%;
  height: 15vh;
  border-bottom: 2px solid var(--orange);
  align-items: center;
  justify-content: center;
}
.publications__events__cal__header h1 {
  color: var(--orange);
  font-weight: 400;
}
.publications__events__cal__date {
  display: flex;
  width: 100%;
  height: 8vh;
  align-items: center;
  padding-left: 20px;
}
.publications__events__month {
  color: var(--orange);
  font-weight: 600;
  font-size: 1.1rem;
}
.publications__events__list {
  display: flex;
  height: 15vh;
  width: 100%;
  align-items: flex-start;
  padding-left: 20px;
}
.publications__events__list p {
  font-size: 1.1rem;
  color: var(--primary);
  font-weight: 300;
}

/* Media queries 300 - 420px */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .publications__events {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 0vh;
    max-height: 100vh;
    margin: 5px;
    background-color: var(--light_gray);
  }
  .publications__events__container {
    display: flex;
    align-items: center;
    min-height: 0vh;
    max-height: 90vh;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
  }
  /*  Gallery */
  .publications__events__gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 38vh;
    max-height: 50vh;
    width: 77%;
    background-color: var(--white);
    margin-top: 15px;
  }
  .publications__event__Img {
    display: flex;
    margin: 3px;
    width: 36vw;
    height: 18vh;
  }
  .publications__event__Img img {
    width: 100%;
    height: 100%;
  }
  /* Calendar */
  .publications__events__calendar {
    display: flex;
    width: 77%;
    height: 50vh;
    background-color: var(--white);
    flex-direction: column;
    align-items: center;
  }
  .publications__events__cal__header {
    height: 7vh;
    width: 94%;
  }
  .publications__events__cal__header h1 {
    color: var(--orange);
    font-weight: 400;
    font-size: 1.2rem;
  }
  .publications__events__cal__date {
    display: flex;
    width: 100%;
    height: 6vh;
    align-items: center;
    padding-left: 20px;
  }
  .publications__events__month {
    color: var(--orange);
    font-weight: 600;
    font-size: 14px;
  }
  .publications__events__list {
    display: flex;
    height: 15vh;
    width: 100%;
    align-items: flex-start;
    padding-left: 20px;
  }
  .publications__events__list p {
    font-size: 12px;
    color: var(--primary);
    font-weight: 300;
  }
}
