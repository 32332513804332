.gallery__project {
  width: 100%;
  max-height: 100vh;
  display: flex;
  background-color: var(--custom_gray);
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.gallery__project__header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 7vh;
  margin-top: 30px;
}
.gallery__project__header p {
  color: var(--white);
  background-color: var(--royal_blue);
  font-weight: 400;
  padding-left: 10px;
  clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
  padding: 3px 75px 3px 0px;
  margin-left: 5rem;
  font-size: 1.6rem;
  opacity: 0.7;
}
.gallery__project__blk {
  background-color: var(--orange);
  padding: 5px;
}
.galley__project__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
}
.gallery__project__grid {
  width: 87vw;
  height: 70vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 220px;
  grid-gap: 20px;
}
.animal__project__one img,
.animal__project__four img,
.shadao__animal__project__one img,
.shadao__sheep img,
.animal__project__three img {
  display: flex;
  opacity: 0.8;
}
.animal__project__three {
  display: flex;
  width: 600px;
  height: 460px;
  grid-row: span 2;
  overflow: hidden;
}
/* Media queries  */
@media screen and (min-width: 300px) and (max-width: 450px) {
  .gallery__project {
    max-height: 95vh;
    width: 100%;
    align-items: center;
  }
  .gallery__project__header {
    height: 5vh;
    margin-top: 40px;
    margin-left: 2px;
  }
  .gallery__project__header p {
    margin-left: 0rem;
    font-size: 1rem;
    margin-left: 5px;
    padding: 3px 50px 3px 0px;
  }
  .galley__project__container {
    width: 100%;
    max-height: 92vh;
    justify-content: center;
    align-items: center;
  }
  .gallery__project__grid {
    width: 100%;
    max-height: 90vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 130px;
    grid-gap: 10px;
  }
  .animal__project__one,
  .animal__project__four,
  .shadao__animal__project__one,
  .shadao__sheep,
  .animal__project__three {
    height: 100%;
    width: 100%;
  }
  .animal__project__one img,
  .animal__project__four img,
  .shadao__animal__project__one img,
  .shadao__sheep img,
  .animal__project__three img {
    width: 100%;
    height: 100%;
  }
  .animal__project__three {
    display: flex;
  }
}
