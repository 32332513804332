.project__disability {
  min-height: 86vh;
  width: 100%;
  background-color: var(--custom_blue);
  display: flex;
  align-items: center;
}
.project__disability__container {
  display: flex;
  width: 100%;
  min-height: 84vh;
  justify-content: space-evenly;
  align-items: center;
}
.project__disability__Img {
  display: flex;
  width: 47%;
  height: 80vh;
  background: url(../../../Assets/kids_disability.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.project__disablity__desc {
  display: flex;
  width: 50%;
  height: 80vh;
  flex-direction: column;
  justify-content: center;
}
.project__disability__header {
  display: flex;
  height: 15vh;
  width: 100%;
  align-items: center;
}
.project__disability__header p {
  font-size: 2.4rem;
  color: var(--royal_blue);
  font-weight: 200;
  border-left: 3px solid var(--orange);
  padding-left: 10px;
}
.project__disability__para {
  display: flex;
  height: 50vh;
  overflow-y: scroll;
  width: 100%;
}
.project__disability__para p {
  font-size: 1.1rem;
  color: var(--primary);
  font-weight: 200;
  padding: 20px 10px 0px 0px;
  text-align: justify;
}

/* Media queries 300 - 420px */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .project__disability {
    min-height: 0vh;
    max-height: 105vh;
    margin: 5px;
  }
  .project__disability__container {
    min-height: 0vh;
    max-height: 103vh;
    align-items: center;
    flex-direction: column;
  }
  .project__disability__Img {
    display: flex;
    width: 100%;
    min-height: 40vh;
    max-height: 47vh;
    background-size: contain;
  }
  .project__disablity__desc {
    display: flex;
    width: 100%;
    min-height: 0vh;
    max-height: 50vh;
    flex-direction: column;
    justify-content: center;
  }
  .project__disability__header {
    display: flex;
    height: 7vh;
    width: 100%;
    align-items: center;
    padding-left: 5px;
  }
  .project__disability__header p {
    font-size: 1.1rem;
    border-left: 3px solid var(--orange);
    padding-left: 5px;
  }
  .project__disability__para {
    display: flex;
    align-items: center;
    min-height: 0vh;
    max-height: 50vh;
    width: 100%;
  }
  .project__disability__para p {
    font-size: 12px;
    color: var(--primary);
    font-weight: 200;
    padding: 10px 5px 5px 5px;
    text-align: justify;
    height: 100%;
  }
}
