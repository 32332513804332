/* .mobile__navigation {
  display: flex;
  max-height: 70vh;
  width: 100%;
  flex-direction: column;
  z-index: 10;
} */
/* .mobile__navigation__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--orange);
  height: 10vh;
  width: 100%;
  border-bottom: 2px solid var(--light_gray);
}
.mobile__navigation__links__container {
  display: flex;
  height: 65vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
}
.mobile__navigation__links {
  display: flex;
  width: 60%;
  height: 60vh;
  background: #1e90ff; /* fallback for old browsers */
/* background: -webkit-linear-gradient(to top, #ff5733, #1e90ff);
  background: linear-gradient(
    to top, */

/* #ff5733,
    #1e90ff */
/* ); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
/* border-radius: 71% 29% 64% 36% / 34% 32% 68% 66%; */
/* }
.mobile__navigation__links nav {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.mobile__navigation__links nav ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 50px;
}
.mobile__navigation__links nav ul li {
  list-style-type: none;
  margin-bottom: 15px;
}
.mobile__navigation__links .link {
  color: var(--white);
  text-decoration: none;
  font-size: 1.2rem;
}
.mobile__navigation__links .active {
  color: var(--white);
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 400;
  padding: 5px 0px;
  border-top: 3px solid var(--white);
  border-bottom: 1px dotted var(--white);
}
.mobile__navigation__links .link:hover {
  background-color: var(--orange);
  padding: 3px 10px;
  opacity: 0.7;
}
.mobile__navigation__menu__ic {
  color: var(--orange);
  font-size: 2rem;
  padding-right: 20px;
}
.mobile__navigation__logo {
  width: 5vh;
  height: 7vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--orange);
  font-weight: 600;
  font-size: 1.3rem;
  margin-left: 20px; 
} */
@media screen and (min-width: 300px) and (max-width: 450px) {
  .mobile__navigation {
    display: flex;
    max-height: 75vh;
    width: 100%;
    flex-direction: column;
    z-index: 40;
  }
  .mobile__navigation__logo {
    width: 10vw;
    height: 10vh;
  }
  .mobile__navigation__logo img {
    width: 5rem;
    height: 5rem;
  }
  .mobile__navigation__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    height: 10vh;
    width: 100%;
    border-bottom: 2px solid var(--light_gray);
    transform: none;
  }
  .mobile__navigation__links__container {
    display: flex;
    min-height: 55vh;
    max-height: 70vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    left: -100%;
    top: 10vh;
    position: absolute;
    z-index: 20;
  }
  .mobile__navigation__links__container.active__mobile {
    left: 0;
    transition: all ease-in 0.5s;
  }
  .mobile__navigation__links {
    display: flex;
    width: 80%;
    height: 50vh;
    background: #1e90ff; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to top,
      #ff5733,
      #1e90ff
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to top,
      #ff5733,
      #1e90ff
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 71% 29% 64% 36% / 34% 32% 68% 66%;
  }
  .mobile__navigation__links nav {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
  }
  .mobile__navigation__links nav ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 50px;
  }
  .mobile__navigation__links nav ul li {
    list-style-type: none;
    margin-bottom: 15px;
  }
  .mobile__navigation__links .link {
    color: var(--white);
    text-decoration: none;
    font-size: 1.2rem;
  }
  .mobile__navigation__links .active {
    color: var(--white);
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 400;
    padding: 5px 0px;
    border-top: 3px solid var(--white);
    border-bottom: 1px dotted var(--white);
  }
  .mobile__navigation__links .link:hover {
    background-color: var(--orange);
    padding: 3px 10px;
    opacity: 0.7;
  }
  .mobile__navigation__menu__ic {
    color: var(--orange);
    font-size: 2rem;
    padding-right: 20px;
  }
  .mobile__navigation__logo {
    width: 5vh;
    height: 7vw;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--orange);
    font-weight: 600;
    font-size: 1.3rem;
    margin-left: 20px;
  }
}
