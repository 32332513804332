.page__not__found {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
}
.page__not__found__container {
  display: flex;
  width: 40%;
  height: 50vh;
  background: url('../../Assets/page__not__found.png');
  background-size: contain;
  background-repeat: no-repeat;
}
