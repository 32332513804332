.scroll__to__top__btn {
  display: flex;
  width: 5vw;
  align-items: center;
  justify-content: center;
  z-index: 30;
  position: absolute;
}
.scroll__top__btn {
  color: var(--orange);
  font-size: 3rem;
  position: fixed;
  top: 150px;
  left: 96%;
}
.scroll__top__btn:hover {
  cursor: pointer;
}
/* Media query 300 - 768px*/
@media screen and (min-width: 300px) and (max-width: 768px) {
  .scroll__to__top__btn {
    display: flex;
    width: 3vw;
    align-items: center;
    justify-content: center;
    z-index: 30;
    position: absolute;
  }
  .scroll__top__btn {
    color: var(--orange);
    font-size: 1.2rem;
    position: fixed;
    top: 150px;
    left: 78%;
  }
}
