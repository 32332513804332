.gallery__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.gallery__modal img {
  display: block;
  max-width: 44%;
  max-height: 82%;
  margin: 60px auto;
  border: 4px solid var(--white);
}
.close__img__ic {
  display: flex;
  color: var(--white);
  font-size: 2.5rem;
  margin-left: auto;
}
.close__img__ic:hover {
  cursor: pointer;
  opacity: 0.7;
}
.gallery__mid__main__img,
.gallery__left__main__img,
.gallery__right__main__img {
  width: 450px;
  height: 100%;
}
.shadao__sheep__img,
.shadao__animal__project__one__img,
.animal__project__one__img,
.animal__project__four__img {
  width: 450px;
  height: 100%;
}
/* Media queries */
@media screen and (min-width: 300px) and (max-width: 450px) {
  .gallery__modal img {
    display: block;
    width: 50%;
    height: 25%;
    max-width: 60%;
    max-height: 40%;
    margin: 30px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    border: 4px solid var(--white);
  }
  .close__img__ic {
    display: flex;
    font-size: 1.5rem;
    margin-left: auto;
    margin-right: 70px;
  }
}
