.get__involved__contact {
  display: flex;
  width: 100%;
  min-height: 90vh;
  align-items: center;
  justify-content: center;
}
.get__involved__contact__map__container {
  display: flex;
  width: 96%;
  min-height: 80vh;
  background-color: var(--custom_blue);
}
.get__involved__contact__form {
  display: flex;
  width: 50%;
  height: 80vh;
  flex-direction: column;
  justify-content: center;
}
.get__involved__contact__map {
  display: flex;
  height: 80vh;
  width: 50%;
}
.get__involved__contact__form__header {
  display: flex;
  width: 100%;
  height: 10vh;
  align-items: center;
  padding: 0px 0px 0px 50px;
}
.get__involved__contact__form__header h1 {
  color: var(--royal_blue);
  font-weight: 200;
  font-size: 2.4rem;
  border-left: 3px solid var(--orange);
  padding-left: 10px;
}
/* Styling form */
.get__involved__contact__form__items {
  display: flex;
  min-height: 50vh;
  justify-content: center;
  margin-top: 20px;
}
.get__involved__contact__form__items input {
  display: flex;
  border: none;
  height: 7vh;
  width: 40vw;
  margin: 15px 0px;
  font-size: 1.1rem;
  outline: none;
  color: var(--primary);
}
.get__involved__contact__form__items textarea {
  display: flex;
  border: none;
  height: 25vh;
  width: 40vw;
  font-size: 1.1rem;
  color: var(--primary);
  margin-bottom: 20px;
  outline: none;
}
.map-container {
  width: 100%;
  min-height: 40vh;
  display: flex;
}
/* Media queries 300 - 420px */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .get__involved__contact {
    display: flex;
    width: 100%;
    min-height: 120vh;
    align-items: center;
    justify-content: center;
    margin: 5px;
  }
  .get__involved__contact__map__container {
    display: flex;
    flex-direction: column;
    width: 96%;
    min-height: 115vh;
    background-color: var(--custom_blue);
  }
  .get__involved__contact__form {
    display: flex;
    width: 100%;
    height: 60vh;
    flex-direction: column;
    justify-content: center;
  }
  .get__involved__contact__map {
    display: flex;
    height: 50vh;
    width: 100%;
  }
  .get__involved__contact__form__header {
    height: 5vh;
    padding: 20px 0px 0px 10px;
  }
  .get__involved__contact__form__header h1 {
    color: var(--royal_blue);
    font-weight: 200;
    font-size: 1.2rem;
    border-left: 3px solid var(--orange);
    padding-left: 10px;
  }
  /* Styling form */
  .get__involved__contact__form__items {
    display: flex;
    min-height: 50vh;
    justify-content: center;
    margin-top: 10px;
  }
  .get__involved__contact__form__items input {
    height: 6vh;
    width: 90vw;
    margin: 10px 0px;
    font-size: 14px;
  }
  .get__involved__contact__form__items textarea {
    height: 20vh;
    width: 90vw;
    font-size: 14px;
    color: var(--primary);
    margin-bottom: 10px;
  }
  .map-container {
    width: 100%;
    min-height: 55vh;
    display: flex;
  }
}
