.button__container {
  color: var(--white);
  display: inline-block;
}
.button__container button {
  background-color: var(--red_orange);
  color: var(--white);
  border: none;
  border-left: 12px solid var(--royal_blue);
  min-height: 7vh;
  min-width: 200px;
  font-size: 1.2rem;
  clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
  padding: 10px 40px 10px 10px;
}
.button__container button:hover {
  cursor: pointer;
  background-color: var(--orange);
}
/* Media queries */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .button__container button {
    background-color: var(--red_orange);
    color: var(--white);
    border: none;
    border-left: 4px solid var(--royal_blue);
    min-height: 4vh;
    min-width: 130px;
    font-size: 14px;
    clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
    padding: 8px 20px 8px 10px;
  }
}
