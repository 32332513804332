.gallery__theme__ {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 50vh;
  background-color: var(--primary);
}
.gallery__theme__ h1 {
  color: var(--white);
  font-size: 7rem;
  padding: 20px 0px;
}
.gallery__theme__ h1 .first {
  color: var(--royal_blue);
}
.gallery__theme__ h1 .last {
  color: var(--orange);
}
/* Media queries */
@media screen and (min-width: 300px) and (max-width: 450px) {
  .gallery__theme__ {
    width: 100%;
    max-height: 30vh;
  }
  .gallery__theme__ h1 {
    color: var(--white);
    font-size: 2rem;
    padding: 20px 0px;
  }
}
