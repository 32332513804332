.objectives {
  display: flex;
  min-height: 110vh;
  align-items: center;
  width: 100%;
}
.community__cards__container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 30px;
}
.flip__card {
  background-color: transparent;
  width: 25vw;
  margin: 0px 5px;
  height: 40vh;
  perspective: 1000px;
  margin-top: 30px;
}

.flip__card__inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip__card:hover .flip__card__inner {
  transform: rotateY(180deg);
}

.community__card__container,
.community__card__back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.community__card__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.community__card__Img__front {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.community__card__Img__front img {
  width: 90px;
  height: 90px;
}
.community__card__title {
  font-size: 1.3rem;
  color: var(--orange);
  font-weight: 600;
  width: 100%;
  text-align: center;
  padding-bottom: 30px;
}
.community__card__back {
  display: flex;
  background-color: var(--royal_blue);
  flex-direction: column;
  align-items: center;
  width: 25vw;
  height: 40vh;
  transform: rotateY(180deg);
}
.community__card__Img__back {
  width: 100%;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.community__card__Img__back img {
  width: 90px;
  height: 90px;
}
.community__card__text {
  height: 15vh;
  color: var(--white);
  padding: 0px 15px 10px 15px;
  display: flex;
  align-items: center;
}
.community__card__text p {
  font-size: 1rem;
}
/* Media queries */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .objectives {
    display: flex;
    min-height: 0vh;
    max-height: 100vh;
    align-items: center;
    width: 100%;
  }
  .community__cards__container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 10px;
  }
  .flip__card {
    background-color: transparent;
    width: 40vw;
    min-height: 0vh;
    max-height: 28vh;
    perspective: 1000px;
    margin-top: 0px;
    margin: 10px;
  }
  .community__card__Img__front {
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .community__card__Img__front img {
    width: 50px;
    height: 50px;
  }
  .community__card__title {
    font-size: 12px;
    color: var(--orange);
    font-weight: 500;
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
  }
  /* Back of the card */
  .community__card__back {
    display: flex;
    background-color: var(--royal_blue);
    flex-direction: column;
    align-items: center;
    width: 40vw;
    min-height: 0vh;
    max-height: 28vh;
    transform: rotateY(180deg);
  }
  .community__card__Img__back {
    width: 100%;
    height: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .community__card__Img__back img {
    width: 50px;
    height: 50px;
  }
  .community__card__text {
    height: 18vh;
    width: 100%;
    color: var(--white);
    padding: 0px 15px 10px 15px;
    display: flex;
    align-items: center;
  }
  .community__card__text p {
    font-size: 11px;
    text-align: center;
  }
}
