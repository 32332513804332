.mission {
  display: flex;
  min-height: 55vh;
  width: 100%;
  background: #0099f7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to left,
    #f11712,
    #0099f7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #f11712,
    #0099f7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.mission__container {
  display: flex;
  width: 100%;
  min-height: 48vh;
}
.mission__statemement {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.mission__header {
  display: flex;
  min-height: 10vh;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.mission__header h1 {
  text-align: center;
  font-size: 3.7rem;
  color: var(--white);
  font-weight: 700;
}
.mission__text {
  color: var(--white);
  font-size: 1.2rem;
  text-align: center;
  font-style: italic;
  font-weight: 300;
  padding: 15px 20px;
}
/* Media queries 300 - 420px */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .mission {
    min-height: 0vh;
    max-height: 50vh;
  }
  .mission__header {
    display: flex;
    min-height: 10vh;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .mission__header h1 {
    font-size: 2.3rem;
  }
  .mission__text {
    color: var(--white);
    font-size: 15px;
    text-align: center;
    font-style: italic;
    font-weight: 300;
    padding: 15px 20px;
  }
}
