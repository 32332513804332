.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 60vh;
  background-color: var(--light_gray);
}
.footer__container {
  width: 100%;
  min-height: 40vh;
  display: flex;
  justify-content: space-evenly;
  margin-top: 70px;
}
.about__footer__links .about {
  list-style-type: none;
}
.about__footer__links .about .about__header {
  font-size: 1.3rem;
  font-weight: 600;
  text-decoration: none;
  color: var(--orange);
}
.about__footer__links .about__link {
  text-decoration: none;
  color: var(--orange);
  font-size: 15px;
}
/* Project Link */
.projects__footer__links .projects {
  list-style-type: none;
}
.projects__footer__links .projects .project__header {
  font-size: 1.3rem;
  font-weight: 600;
  text-decoration: none;
  color: var(--orange);
}
.projects__footer__links .projects .project__link {
  font-size: 15px;
  text-decoration: none;
  color: var(--orange);
}
/* Publications Links */
.publications__footer__links .publications__footer {
  list-style-type: none;
}
.publications__footer .publications__footer__header {
  text-decoration: none;
  font-weight: 600;
  font-size: 1.3rem;
  color: var(--orange);
}
.publications__footer__links .publications__footer .publication__link {
  text-decoration: none;
  font-size: 15px;
  color: var(--orange);
}
/* Get Involved */
.getInvolved__footer__links .getInvolved__footer .get__Involved__link {
  list-style-type: none;
}
.get__Involved__header {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--orange);
  text-decoration: none;
}
.get__involved__form__container > form {
  display: flex;
}
.get__involved__form__container > form input {
  width: 220px;
  max-height: 7vh;
  outline: none;
  font-size: 17px;
  border: none;
}
.get__involved__form__container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 10vh;
  width: 90%;
  margin-left: 40px;
}
/* Social Media */
.footer__social__media {
  display: flex;
  justify-content: flex-start;
  color: var(--orange);
  margin: 10px 0px 0px 25px;
}
.footer__social__media div {
  /* padding: 0px 8px; */
  /* font-size: 1.5rem; */
  display: flex;
  height: 6vh;
  width: 3vw;
  align-items: center;
  justify-content: center;
  background-color: var(--orange);
  border-radius: 999px;
  margin-left: 13px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.footer__whats__app__ic,
.footer__gmail__ic,
.footer__facebook__ic {
  color: var(--white);
  font-size: 2rem;
  margin-top: 4px;
}
.footer__copyright__container {
  display: flex;
  width: 100%;
  min-height: 5vh;
  align-items: center;
  justify-content: center;
}
.footer__copyright__contents > p {
  color: var(--orange);
  font-weight: 400;
  font-size: 1.1rem;
}
/* Footer divider */
.footer__divider__container {
  display: flex;
  min-height: 3vh;
  width: 100%;
  justify-content: center;
}
.footer__divider__container .liner {
  border-top: 1px solid var(--orange);
  width: 95%;
  height: 2vh;
}
/* Media queries 300 - 420px */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .footer {
    min-height: 0vh;
    max-height: 130vh;
  }
  .footer__container {
    width: 100%;
    min-height: 0vh;
    max-height: 127vh;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    flex-direction: column;
    padding-left: 0px;
  }
  .about__footer__links .about {
    padding: 10px 30px;
  }
  .about__footer__links .about .about__header {
    font-size: 1.1rem;
    font-weight: 600;
    text-decoration: none;
    color: var(--orange);
    padding-bottom: 20px;
  }
  .about__footer__links .about__link {
    text-decoration: none;
    color: var(--orange);
    font-size: 13px;
  }
  /* End about links */
  .projects__footer__links .projects {
    list-style-type: none;
    padding: 10px 30px;
  }
  .projects__footer__links .projects .project__header {
    font-size: 1.1rem;
    font-weight: 600;
    text-decoration: none;
    color: var(--orange);
  }
  .projects__footer__links .projects .project__link {
    font-size: 13px;
    text-decoration: none;
    color: var(--orange);
  }
  /* End project links */
  .publications__footer__links .publications__footer {
    list-style-type: none;
    padding: 10px 30px;
  }
  .publications__footer .publications__footer__header {
    font-size: 1.1rem;
  }
  .publications__footer__links .publications__footer .publication__link {
    font-size: 13px;
  }
  /* End publication links */
  .getInvolved__footer {
    width: 100%;
    padding: 0px 30px;
  }
  .getInvolved__footer__links {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0px;
    margin: 0px;
  }
  .getInvolved__footer__links .getInvolved__footer .get__Involved__link {
    list-style-type: none;
    padding: 10px 0px 0px 0px;
  }
  .get__Involved__header {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--orange);
    text-decoration: none;
  }
  .get__involved__form__container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    min-height: 0vh;
    max-height: 15vh;
    width: 90%;
    margin-left: 10px;
  }
  .get__involved__form__container form {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .get__involved__form__container > form input {
    width: 170px;
    outline: none;
    font-size: 13px;
    border: none;
  }
  /* End of form */
  .footer__social__media {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    color: var(--orange);
    margin: 5px 0px 10px 20px;
  }
  .footer__social__media div {
    /* padding: 0px 8px; */
    /* font-size: 1.5rem; */
    padding: 2px 3px;
    display: flex;
    height: 3vh;
    width: 6vw;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 50%;
    margin-left: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  .footer__whats__app__ic {
    color: var(--orange);
    font-size: 20px;
    margin-top: 4px;
  }
  .footer__gmail__ic,
  .footer__facebook__ic {
    color: var(--orange);
    font-size: 22px;
    margin-top: 6px;
  }
  /* End of social media */
  .footer__copyright__container {
    display: flex;
    width: 100%;
    min-height: 0vh;
    max-height: 4vh;
    align-items: center;
    justify-content: center;
  }
  .footer__copyright__contents > p {
    color: var(--orange);
    font-weight: 300;
    font-size: 13px;
    padding-bottom: 5px;
  }
}
