.get__involved__donate {
  display: flex;
  width: 100%;
  min-height: 60vh;
  align-items: center;
  background: #1fa2ff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #a6ffcb,
    #12d8fa,
    #1fa2ff
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #a6ffcb,
    #12d8fa,
    #1fa2ff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.get__involved__donate__container {
  display: flex;
  width: 100%;
  min-height: 40vh;
  color: var(--white);
  justify-content: space-evenly;
}
.get__involved__donate__info {
  display: flex;
  width: 60%;
  min-height: 35vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 2px solid var(--white);
}
.get__involved__donate__info h1 {
  font-size: 3rem;
}
.get__involved__donate__info p {
  font-size: 1.3rem;
  text-align: center;
  margin-top: 10px;
}
.get__involved__donate__contact {
  display: flex;
  flex-direction: column;
  min-height: 25vh;
  width: 30%;
  justify-content: center;
}
.get__involved__contact__one {
  padding: 0px 0px 10px 0px;
}
.get__involved__contact__one h3 {
  height: 5vh;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
}
.get__involved__contact__one p {
  font-size: 1.2rem;
  margin-left: 48px;
}
.get__involved__mailbox {
  font-size: 1.6rem;
  margin-right: 20px;
}
.get__involved__email {
  display: flex;
  align-items: center;
  height: 7vh;
  margin-bottom: 10px;
}
.get__involved__email__ic {
  font-size: 1.6rem;
}
.get__involved__email__add {
  font-size: 1.2rem;
  margin: 0px 0px 0px 25px;
}
.get__involved__contact__two {
  display: flex;
  width: 60%;
  min-height: 7vh;
  align-items: center;
  border: 2px solid var(--white);
  background-color: white;
  clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
  margin-top: 10px;
  padding: 0px 3px;
}
.get__involved__contact__two:hover {
  cursor: pointer;
}
.get__involved__whatsapp {
  font-size: 1.5rem;
  background: var(--dodge_blue);
  border: 3px solid var(--dodge_blue);
  padding: 2px;
  border-radius: 999px;
}
.get__involved__whatsapp__number {
  font-size: 1.1rem;
  color: var(--dodge_blue);
  margin-left: 5px;
  font-weight: 600;
}
/* Media queries 300 - 420px */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .get__involved__donate {
    width: 100%;
    min-height: 0vh;
    max-height: 80vh;
    align-items: center;
  }
  .get__involved__donate__container {
    display: flex;
    width: 100%;
    min-height: 0vh;
    max-height: 75vh;
    color: var(--white);
    flex-direction: column;
    align-items: center;
  }
  .get__involved__donate__info {
    display: flex;
    width: 90%;
    min-height: 40vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: none;
    border-bottom: 2px solid var(--white);
  }
  .get__involved__donate__info h1 {
    font-size: 1.7rem;
  }
  .get__involved__donate__info p {
    font-size: 1rem;
    text-align: center;
  }
  .get__involved__donate__contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 40vh;
    width: 100%;
    justify-content: center;
  }
  .get__involved__contact__one {
    padding: 0px 0px 10px 0px;
  }
  .get__involved__contact__one h3 {
    font-size: 1.1rem;
  }
  .get__involved__contact__one p {
    font-size: 1rem;
    margin-left: 48px;
  }
  .get__involved__mailbox {
    font-size: 1.3rem;
    margin-right: 20px;
  }
  .get__involved__email {
    display: flex;
    align-items: center;
    height: 5vh;
    margin-bottom: 10px;
  }
  .get__involved__email__ic {
    font-size: 1.2rem;
  }
  .get__involved__email__add {
    font-size: 1rem;
    margin: 0px 0px 0px 25px;
  }
  .get__involved__contact__two {
    display: flex;
    width: 40%;
    min-height: 0vh;
    align-items: center;
    border: none;
    background-color: white;
    clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
    margin-top: 5px;
    padding: 5px;
    margin-right: 20px;
  }
  .get__involved__whatsapp {
    font-size: 1rem;
  }
  .get__involved__whatsapp__number {
    font-size: 12px;
  }
}
