.gallery__banner {
  display: flex;
  flex-direction: column;
  min-height: 110vh;
  max-height: 130vh;
  width: 100%;
  position: relative;
}
.gallery__banner__nav__container {
  /* position: absolute; */
  top: 0vh;
  /* position: fixed; */
  width: 100%;
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
/* .mobile__navigation {
  display: none;
} */
.lushoto__footage {
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  position: absolute;
}
.gallery__banner__lower {
  /* display: flex; */
  /* position: fixed; */
  position: absolute;
  bottom: 0;
  width: 97%;
  background: url(../../../Assets/wave.svg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  height: 15vh;
  align-items: center;
  justify-content: flex-end;
}
.gallery__video__controller {
  display: flex;
  font-size: 4rem;
  font-weight: 300;
  color: var(--orange);
  margin: 30px 50px 0px 0px;
  margin-left: auto;
}
.gallery__video__controller:hover {
  cursor: pointer;
}
.gallery__theme__contaner {
  /* top: 40vh; */
  /* position: fixed; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
}
.gallery__theme {
  margin-top: 30px;
  height: 45vh;
  width: 66vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery__theme h1 {
  font-size: 12rem;
  position: absolute;
  mix-blend-mode: difference;
  z-index: 3;
  filter: invert(1);
  padding: 0 30px;
  opacity: 0.8;
}
/* Media queries */
/*  */
@media screen and (min-width: 300px) and (max-width: 450px) {
  .gallery__banner {
    min-height: 70vh;
    max-height: 80vh;
  }
  .gallery__banner__nav__container {
    display: none;
  }
  .gallery__banner__nav__mobile {
    display: flex;
    width: 100%;
    height: 10vh;
  }
  .lushoto__footage {
    width: 100%;
    height: 94%;
    z-index: -1;
    object-fit: 100%;
    position: absolute;
  }
  .gallery__banner__lower {
    width: 90.5%;
    background-size: 100%;
    height: 15vh;
    align-items: center;
    top: 87%;
  }
  .gallery__video__controller {
    font-size: 1.6rem;
    font-weight: 300;
    margin: 30px 0px 0px 0px;
    margin-left: auto;
  }
  .gallery__theme__contaner {
    /* top: 40vh; */
    /* position: fixed; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20vh;
    margin-top: 30%;
  }
  .gallery__theme {
    margin-top: 30px;
    height: 15vh;
    width: 40vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .gallery__theme h1 {
    font-size: 4rem;
    font-weight: 800;
    position: absolute;
    mix-blend-mode: difference;
    z-index: 3;
    filter: invert(1);
    padding: 0 30px;
    opacity: 0.8;
  }
}
