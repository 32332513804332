.carousel {
  display: flex;
  align-items: center;
  min-height: 73vh;
  width: 100%;
  background-color: var(--light_gray);
}
.carousel__Img__container {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 70vh;
  margin: 5px;
}
.carousel__Img__container img {
  width: 100%;
  height: 100%;
}
.carousel__desc {
  display: flex;
  align-self: flex-end;
  position: absolute;
  height: 10%;
  width: 98.5%;
  background: rgb(5, 5, 5);
  background: linear-gradient(
    0deg,
    rgba(5, 5, 5, 1) 24%,
    rgba(243, 244, 245, 0.05) 100%
  );
  z-index: 1;
}
.carousel__desc p {
  font-size: 1.3rem;
  border-left: 4px solid var(--orange);
  color: var(--white);
  padding: 3px 10px;
  font-weight: 200;
  margin: 0px 0px 10px 10px;
  z-index: 1;
}
/* Media queries */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .carousel {
    display: flex;
    align-items: center;
    min-height: 0vh;
    max-height: 35vh;
    width: 100%;
    background-color: var(--light_gray);
  }
  .carousel__Img__container {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 30vh;
    margin: 2px 3px;
  }
  .carousel__Img__container img {
    width: 100%;
    height: 95%;
  }
  .carousel__desc p {
    font-size: 10px;
    border-left: 3px solid var(--orange);
    color: var(--white);
    padding: 0px 4px;
    font-weight: 200;
  }
  .carousel__desc {
    display: flex;
    align-self: flex-end;
    position: absolute;
    height: 9%;
    width: 97%;
  }
}
