@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  scroll-behavior: smooth;
}
/* Color codes */
:root {
  --white: hsl(0, 0%, 100%);
  --orange: #ff5733;
  --red_orange: #ff4433;
  --dark_orange: #8b4000;
  --dodge_blue: #1e90ff;
  --royal_blue: #4169e1;
  --powder_blue: #b0e0e6;
  --sky_blue: #add8e6;
  --custom_blue: #e0fff6;
  --light_cyan: #e0ffff;
  --gainsboro: #dcdcdc;
  --silver: #c0c0c0;
  --slate_gray: #708090;
  --custom_gray: #eef2f5;
  --light_gray: #f7faf6;
  --primary: #373737;
}
