.publications__banner {
  display: flex;
  min-height: 129vh;
  width: 100%;
  background: url(../../../Assets/publication_bcg.png);
  background-repeat: no-repeat;
  background-size: 81%;
  /* background-position: bottom; */
  background-position: right;
  flex-direction: column;
}
.publications__nav__container {
  display: flex;
  width: 100%;
  height: 25vh;
  align-items: center;
  justify-content: space-evenly;
}
.publications__theme {
  display: flex;
  width: 50%;
  min-height: 70vh;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 20px;
}
.publications__theme__header {
  display: flex;
  width: 100%;
  height: 7vh;
  flex-direction: column;
}
.publications__theme__header .publications__theme__para1 {
  font-size: 3.4rem;
  font-weight: 800;
  color: var(--royal_blue);
}
.publications__theme__para2 {
  display: flex;
  width: 90%;
  font-size: 1.1rem;
  color: var(--primary);
  margin-bottom: 30px;
  font-weight: 200;
}
.publications__theme__btn {
  width: 100%;
  display: flex;
  min-height: 20vh;
  margin-top: 50px;
  align-items: flex-end;
}
/* Media queries 300 - 420px */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .publications__banner {
    display: flex;
    min-height: 0vh;
    max-height: 130vh;
    width: 100%;
    background: url(../../../Assets/publication_bcg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
    flex-direction: column;
    justify-content: flex-end;
    background-position-y: 10vh;
  }
  .publications__nav__container {
    display: none;
  }
  .publications__theme {
    width: 100%;
    min-height: 0vh;
    max-height: 80vh;
    margin-top: 320px;
  }
  .publications__theme__header {
    display: flex;
    width: 100%;
    height: 10vh;
    flex-direction: column;
  }
  .publications__theme__header .publications__theme__para1 {
    font-size: 1.3rem;
    font-weight: 700;
    color: var(--royal_blue);
  }
  .publications__theme__para2 {
    display: flex;
    width: 90%;
    font-size: 13px;
    margin-bottom: 0px;
    font-weight: 200;
  }
  .publications__theme__btn {
    width: 100%;
    display: flex;
    min-height: 0vh;
    max-height: 10vh;
    align-items: flex-end;
    margin-top: 60px;
  }
}
