.get__involved__banner {
  display: flex;
  flex-direction: column;
  min-height: 110vh;
  width: 100%;
  background: url(../../../Assets/contact_banner.png);
  background-size: 100%;
  background-repeat: no-repeat;
}
.get__involved__navigation {
  width: 100%;
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.get__involved__container {
  display: flex;
  width: 100%;
  min-height: 60vh;
  align-items: center;
}
.get__involved__theme {
  display: flex;
  flex-direction: column;
  min-height: 30vh;
  width: 80%;
  justify-content: center;
  padding: 0px 0px 50px 30px;
}
.get__involved__theme h1 {
  color: var(--royal_blue);
  font-weight: 800;
  font-size: 3.5rem;
}
.get__involved__banner__para {
  font-size: 1.1rem;
  color: var(--primary);
  font-weight: 200;
  width: 50%;
  padding: 0px 40px 20px 0px;
  width: 47%;
}
.get__ivolved__social__icons {
  width: 15%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.get__ivolved__social__icons div a,
.get__ivolved__facebook__ic,
.get__involved__whats__app__ic {
  color: var(--white);
  text-decoration: none;
  font-size: 2rem;
}
.get__involved__whats__app__ic:hover,
.get__ivolved__facebook__ic:hover {
  cursor: pointer;
}
.get__involved__btn {
  z-index: 1;
}
/* Media queries 300 - 420px */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .get__involved__banner {
    display: flex;
    min-height: 0vh;
    max-height: 60vh;
    width: 100%;
    background: url(../../../Assets/contact_banner.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 10vh;
  }
  .get__involved__container {
    display: flex;
    flex-direction: column;
    width: 80%;
    min-height: 0vh;
    max-height: 40vh;
    align-items: center;
    margin-top: 60px;
  }
  .get__involved__theme {
    display: flex;
    height: 0vh;
    max-height: 30vh;
    width: 100%;
    justify-content: center;
    padding: 0px 0px 10px 20px;
  }
  .get__involved__theme h1 {
    color: var(--royal_blue);
    font-weight: 700;
    font-size: 1.3rem;
  }
  .get__involved__banner__para {
    font-size: 11px;
    color: var(--primary);
    font-weight: 200;
    padding: 90px 0px 10px 0px;
    width: 90%;
  }
  .get__ivolved__social__icons {
    width: 8%;
    height: 20vh;
    display: flex;
    position: absolute;
    flex-direction: row;
    margin-left: 90%;
  }
  .get__ivolved__social__icons div {
    height: 5vh;
    width: 100%;
    margin-bottom: 70px;
  }

  .get__ivolved__facebook__ic,
  .get__involved__whats__app__ic {
    color: var(--white);
    text-decoration: none;
    font-size: 1.2rem;
    margin-top: 3px;
  }
  .get__ivolved__social__icons div a {
    font-size: 1.3rem;
  }
}
