.home__banner {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background: url(../../../Assets/home_bcg_hero.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}
.home__banner__top {
  min-height: 25vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.banner__nav__container {
  display: flex;
  height: 22vh;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  padding-top: 50px;
}
.home__banner__container {
  display: flex;
  width: 80%;
  min-height: 95vh;
}
.banner__Img__container {
  width: 100%;
  height: 85vh;
}
.banner__Img__container > img {
  width: 100%;
  height: 100%;
}
.paging-item.active {
  fill: var(--orange) !important;
}
/* Mission Statement */
.vision_statement__container {
  max-width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 30px;
  height: 70vh;
  margin-top: auto;
}
.vision__statement__top {
  font-size: 3.7rem;
  font-weight: 800;
  color: var(--royal_blue);
  padding-left: 7rem;
  padding-bottom: 10px;
}
.vision__statement__bottom {
  font-size: 3rem;
  font-weight: 100;
  color: var(--orange);
  margin-top: -25px;
  margin-bottom: 15px;
}
/* Media queries */
@media screen and (max-width: 768px) {
  .home__banner__top {
    /* display: none; */
    display: none;
  }
}
/* Screen sizes 350 -- 425 */
@media screen and (min-width: 320px) and (max-width: 450px) {
  .home__banner__top {
    display: none;
  }
  .home__banner {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 0vh;
    max-height: 70vh;
    background-size: 100%;
    background-position: top;
    background-position-y: 10vh;
  }
  /* .banner__nav__container {
    display: none;
  } */
  .vision_statement__container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0px;
    min-height: 0vh;
    max-height: 58vh;
    margin-bottom: 0px;
    padding: 0px 0px 10px 10px;
  }
  .vision__statement__top {
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--royal_blue);
    padding-left: 1rem;
    padding-bottom: 1px;
  }
  .vision__statement__bottom {
    font-size: 1.2rem;
    width: 100%;
    font-weight: 200;
    color: var(--orange);
    margin-top: -5px;
    margin-bottom: 10px;
  }
}
