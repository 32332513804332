.publications__newsletter {
  display: flex;
  min-height: 60vh;
  align-items: center;
  width: 100%;
  background-image: radial-gradient(
    circle,
    #ff5733,
    #fd3a5b,
    #ed2d7f,
    #cf389e,
    #a349b4,
    #8262cc,
    #5776da,
    #0086df,
    #00a5ea,
    #00c0ed,
    #4adaec,
    #89f2eb
  );
}
.publications__newsletter__container {
  display: flex;
  width: 100%;
  min-height: 30vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.publications__newsletter__header {
  height: 10vh;
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: center;
}
.publications__newsletter__header h1 {
  font-size: 4.2rem;
  color: var(--white);
  font-weight: 700;
}
.publications__newsletter__register {
  display: flex;
  width: 100%;
  height: 15vh;
  justify-content: center;
  align-items: center;
}
.publications__newsletter__container form {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.publications__newsletter__register input {
  border: none;
  height: 6vh;
  width: 50%;
  outline: none;
  font-size: 1.2rem;
  padding: 4px;
}
/* Media queries */
@media screen and (min-width: 300px) and (max-width: 768px) {
  .publications__newsletter {
    min-height: 40vh;
    max-height: 50vh;
  }
  .publications__newsletter__container {
    display: flex;
    width: 100%;
    min-height: 20vh;
  }
  .publications__newsletter__header {
    height: 10vh;
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: center;
  }
  .publications__newsletter__header h1 {
    font-size: 1.5rem;
    color: var(--white);
    font-weight: 700;
  }
  /*  */
  .publications__newsletter__register {
    display: flex;
    width: 100%;
    height: 10vh;
    justify-content: center;
    align-items: center;
  }
  .publications__newsletter__register input {
    height: 0vh;
    height: 30px;
    width: 55%;
    font-size: 14px;
  }
}
