.profile {
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}
.profile__container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 88vh;
  margin: 40px 0px 0px 0px;
}
.profile__img__container {
  display: flex;
  width: 52%;
  height: 90vh;
  background: url(../../../Assets/about_profile.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}
.profile__desc__container {
  display: flex;
  width: 45%;
  min-height: 80vh;
  flex-direction: column;
  justify-content: center;
}
.profile__desc__header {
  display: flex;
  height: 15vh;
  align-items: center;
  width: 100%;
}
.profile__desc__header h1 {
  color: var(--royal_blue);
  border-left: 3px solid var(--orange);
  font-size: 2.4rem;
  font-weight: 200;
  padding: 0px 10px;
}
.about__desc__text {
  display: flex;
  min-height: 40vh;
  width: 100%;
  align-items: flex-start;
}
.about__desc__text p {
  text-align: justify;
  font-size: 1.1rem;
  color: var(--primary);
  font-weight: 200;
}
/* Media queries 300 - 420px */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .profile {
    min-height: 0vh;
    max-height: 100vh;
    margin: 5px;
  }
  .profile__container {
    justify-content: center;
    align-items: center;
    min-height: 0vh;
    max-height: 95vh;
    flex-direction: column;
    margin: 30px 0px 0px 0px;
  }
  .profile__img__container {
    display: flex;
    width: 100%;
    height: 45vh;
  }
  .profile__desc__container {
    display: flex;
    width: 100%;
    min-height: 0vh;
    max-height: 50vh;
  }
  .profile__desc__header {
    display: flex;
    height: 7vh;
    align-items: center;
    width: 100%;
  }
  .profile__desc__header h1 {
    font-size: 1.3rem;
    font-weight: 200;
    padding: 0px 0px 0px 5px;
  }
  .about__desc__text {
    min-height: 0vh;
    max-height: 39vh;
    align-items: flex-start;
  }
  .about__desc__text p {
    text-align: justify;
    font-size: 13px;
    color: var(--primary);
    font-weight: 200;
    padding-bottom: 10px;
  }
}
