.publications__article {
  display: flex;
  min-height: 110vh;
  width: 100%;
  align-items: center;
}
.publications__article__container {
  display: flex;
  width: 100%;
  min-height: 105vh;
  align-items: center;
  margin: 70px 0px 0px 0px;
  flex-direction: column;
}
.publications__article__header {
  display: flex;
  width: 100%;
  min-height: 10vh;
  align-items: flex-end;
}
.publications__article__header h1 {
  font-size: 2.5rem;
  color: var(--royal_blue);
  font-weight: 200;
  padding-left: 10px;
  border-left: 3px solid var(--orange);
  margin-left: 30px;
}
.publications__article__doc {
  display: flex;
  width: 48%;
  height: 95vh;
  min-height: 0vh;
  align-items: center;
  background: url(../../../Assets/article_bcg.png);
  background-size: 98%;
  background-repeat: no-repeat;
}
.publication__article__link {
  display: flex;
  width: 47%;
  min-height: 90vh;
  align-items: center;
}
.publications__article__doc__link {
  display: flex;
  width: 100%;
  min-height: 97vh;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
}
.publication__article__title {
  display: flex;
  border: 5px solid var(--white);
  min-height: 30vh;
  width: 30vw;
  margin-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.publication__article__title h3 {
  color: var(--white);
  font-size: 2rem;
}
.publication__article__title p {
  font-size: 1.3rem;
  padding-top: 10px;
  color: var(--white);
}

.publications__article__link__body {
  display: flex;
  width: 42vw;
  height: 27vh;
  background-color: var(--orange);
  flex-direction: column;
  align-items: center;
}
.publications__article__link__title {
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  background-color: var(--royal_blue);
  justify-content: center;
}
.publications__article__link__title h6 {
  font-weight: 600;
  font-size: 14px;
  color: var(--light_gray);
}

.publications__article__link__btn {
  height: 15vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.publications__article__link__btn {
  color: var(--white);
  font-size: 1.3rem;
  border: 3px solid var(--white);
  width: 200px;
  height: 50px;
  margin-top: 27px;
}
.publications__article__link__btn:hover {
  cursor: pointer;
}
/* Media queries 300 - 420px */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .publications__article {
    display: flex;
    min-height: 10vh;
    max-height: 120vh;
    width: 100%;
    margin: 5px;
  }
  .publications__article__container {
    min-height: 0vh;
    max-height: 110vh;
    margin: 20px 0px 0px 0px;
    flex-direction: column;
  }
  .publications__article__header {
    min-height: 0;
    max-height: 5vh;
    align-items: center;
    width: 100%;
  }
  .publications__article__header h1 {
    font-size: 1.3rem;
    padding-left: 5px;
    margin-left: 10px;
  }
  .publications__article__doc__link {
    width: 100%;
    min-height: 0vh;
    max-height: 70vh;
    flex-direction: column;
    justify-content: flex-end;
  }
  .publications__article__doc {
    display: flex;
    width: 100%;
    height: 45vh;
    background: url(../../../Assets/article_bcg.png);
    background-size: 100%;
    background-repeat: no-repeat;
    justify-content: flex-start;
    align-items: center;
    margin: 5px;
  }
  .publication__article__title {
    display: flex;
    border: 5px solid var(--white);
    min-height: 0vh;
    max-height: 18vh;
    width: 60vw;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .publication__article__title h3 {
    color: var(--white);
    font-size: 1.3rem;
  }
  .publication__article__title p {
    font-size: 11px;
    padding-top: 10px;
    color: var(--white);
  }
  /*  */
  .publication__article__link {
    display: flex;
    width: 100%;
    min-height: 0vh;
    max-height: 30vh;
    align-items: center;
  }
  .publications__article__link__body {
    display: flex;
    width: 100%;
    height: 20vh;
    background-color: var(--orange);
    flex-direction: column;
    align-items: center;
  }
  .publications__article__link__title {
    width: 100%;
    height: 7vh;
    display: flex;
    align-items: center;
    background-color: var(--royal_blue);
    justify-content: center;
    padding: 5px 0px;
  }
  .publications__article__link__title h6 {
    font-weight: 600;
    font-size: 12px;
    color: var(--light_gray);
    text-align: center;
  }

  .publications__article__link__btn {
    height: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .publications__article__link__btn {
    color: var(--white);
    font-size: 13px;
    border: 3px solid var(--white);
    width: 120px;
    height: 35px;
    margin-bottom: 25px;
  }
  .publications__article__link__body {
    display: flex;
    width: 90%;
    height: 13vh;
    background-color: var(--orange);
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
