.about__banner {
  display: flex;
  flex-direction: column;
  min-height: 115vh;
  width: 100%;
  background: url(../../../Assets/about_bcg_hero.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}
.about__banner__container {
  height: 25vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.about__org__theme {
  display: flex;
  min-height: 20vh;
  width: 45%;
  margin: 170px 0px 0px 40px;
}
.about__org__theme .major {
  color: var(--royal_blue);
  font-size: 3.6rem;
  font-weight: 800;
  padding-left: 12rem;
}
.about__org__theme .minor {
  color: var(--orange);
  font-size: 2.7rem;
  font-weight: 100;
}
/* Media queries 300 - 420px */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .about__banner {
    display: flex;
    flex-direction: row;
    min-height: 0vh;
    max-height: 100vh;
    flex-direction: column;
    width: 100%;
    background-position-y: 10vh;
  }
  .about__banner__container {
    display: none;
  }
  .about__banner__container__mobile {
    display: flex;
    width: 100%;
  }
  .about__org__theme {
    display: flex;
    align-items: center;
    min-height: 30vh;
    width: 45%;
    margin: 0px 0px 0px 5px;
  }
  .about__org__theme .major {
    font-size: 1.3rem;
    font-weight: 700;
    padding-left: 2.4rem;
  }
  .about__org__theme .minor {
    font-size: 13px;
    font-weight: 200;
  }
}
