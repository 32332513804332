.governance {
  display: flex;
  width: 100%;
  min-height: 125vh;
  flex-direction: column;
  justify-content: center;
}
.governance__header {
  display: flex;
  width: 100%;
  height: 7vh;
  align-items: center;
  justify-content: center;
}
.governance__header h1 {
  color: var(--royal_blue);
  font-size: 2.7rem;
  border-left: 4px solid var(--orange);
  font-weight: 200;
  padding-left: 20px;
}
.governance__cards {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: space-evenly;
  margin: 60px 0px 0px 0px;
}
.governance__members {
  display: flex;
  flex-direction: column;
  background-color: var(--primary);
  width: 30vw;
  height: 100vh;
}
.governance__board__of__directors {
  display: flex;
  flex-direction: column;
  background-color: var(--orange);
  width: 30vw;
  height: 100vh;
}
.governance__management__team {
  display: flex;
  flex-direction: column;
  background-color: var(--royal_blue);
  width: 30vw;
  height: 100vh;
}
.governace__icon__container {
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.governace__icon__container img {
  width: 80px;
  height: 80px;
}
.governance__card__header {
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.governance__card__header h3 {
  font-weight: 300;
  color: var(--white);
  font-size: 1.5rem;
}
.governance__card__divider {
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.governance__card__divider img {
  width: 70%;
}
.governance__card__text {
  display: flex;
  width: 100%;
  min-height: 60vh;
  margin-top: 30px;
}
.governance__card__text p {
  color: var(--white);
  font-size: 15px;
  padding: 20px;
  text-align: justify;
}
/* Media queries 300 - 420px */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .governance {
    display: flex;
    min-height: 0vh;
    max-width: 300vh;
    align-items: center;
  }
  .governance__header {
    display: flex;
    width: 100%;
    height: 7vh;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .governance__header h1 {
    font-size: 1.3rem;
    padding-left: 10px;
  }
  .governance__cards {
    min-height: 0vh;
    max-height: 270vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 96%;
    margin: 10px 0px 0px 0px;
  }
  .governance__members {
    width: 94vw;
    height: 80vh;
    margin-bottom: 10px;
  }
  .governance__board__of__directors {
    width: 94vw;
    height: 85vh;
    margin-bottom: 10px;
  }
  .governance__management__team {
    width: 94vw;
    height: 80vh;
    margin-bottom: 10px;
  }
  .governace__icon__container {
    width: 100%;
    height: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
  .governace__icon__container img {
    width: 50px;
    height: 50px;
  }
  .governance__card__header {
    height: 4vh;
    margin-top: 30px;
  }
  .governance__card__header h3 {
    font-size: 1.2rem;
  }
  .governance__card__divider {
    height: 4vh;
    margin-top: 30px;
  }
  .governance__card__divider img {
    width: 50%;
  }
  .governance__card__text {
    display: flex;
    width: 100%;
    min-height: 0vh;
    max-height: 50vh;
    margin-top: 10px;
  }
  .governance__card__text p {
    color: var(--white);
    font-size: 12px;
    padding: 20px;
    text-align: justify;
  }
}
