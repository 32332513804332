.project__animal {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 90vh;
}
.project__animal__container {
  display: flex;
  min-height: 88vh;
  align-items: center;
  justify-content: space-evenly;
}
.project__animal__Img {
  background: url(../../../Assets/project_animal.png);
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  width: 47%;
  height: 85vh;
}
.project__animal__desc {
  display: flex;
  flex-direction: column;
  width: 48%;
  min-height: 85vh;
  justify-content: center;
}
.project__animal__header {
  display: flex;
  height: 15vh;
  align-items: center;
  width: 100%;
}
.project__animal__header p {
  font-size: 2.4rem;
  color: var(--royal_blue);
  font-weight: 200;
  border-left: 3px solid var(--orange);
  padding-left: 10px;
}
.project__animal__para {
  display: flex;
  min-height: 20vh;
  width: 100%;
}
.project__animal__para p {
  color: var(--primary);
  font-size: 1.1rem;
  font-weight: 200;
  text-align: justify;
}
/* Media queries 300 - 420px */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .project__animal {
    display: flex;
    min-height: 0vh;
    max-height: 120vh;
    align-items: center;
    justify-content: center;
    margin: 5px;
  }
  .project__animal__container {
    display: flex;
    min-height: 0vh;
    max-height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .project__animal__Img {
    width: 100%;
    height: 0vh;
    height: 45vh;
  }
  .project__animal__desc {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 0vh;
    max-height: 52vh;
    justify-content: center;
  }
  .project__animal__header {
    height: 7vh;
    padding: 0px 0px 0px 5px;
  }
  .project__animal__header p {
    font-size: 1.2rem;
    padding: 0px 0px 0px 5px;
  }
  .project__animal__para {
    display: flex;
    align-items: center;
    min-height: 0vh;
    max-height: 44vh;
    width: 100%;
  }
  .project__animal__para p {
    color: var(--primary);
    font-size: 12px;
    font-weight: 200;
    text-align: justify;
    padding: 0px 5px 10px 5px;
  }
}
