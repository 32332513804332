.project__banner {
  display: flex;
  width: 100%;
  min-height: 110vh;
  background: url(../../../Assets/project_banner.png);
  background-repeat: no-repeat;
  background-size: 100%;
}
.project__banner__container {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  width: 100%;
}
.project__banner__nav {
  display: flex;
  width: 100%;
  height: 25vh;
  align-items: center;
  justify-content: space-evenly;
}
.project__banner__theme {
  display: flex;
  width: 45%;
  height: 30vh;
  flex-direction: column;
  justify-content: center;
  padding: 100px 0px 0px 20px;
}
.project__banner__p1 {
  font-size: 3.7rem;
  font-weight: 800;
  color: var(--royal_blue);
  padding-left: 6.2rem;
  margin-bottom: -30px;
}
.project__banner__p1__minor__ {
  font-size: 3rem;
  font-weight: 100;
  color: var(--orange);
}
.project__banner__p2 {
  width: 75%;
  color: var(--primary);
  padding: 10px 0px 20px 0px;
  font-weight: 200;
  font-size: 1rem;
}
/* Media queries 300 - 420px */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .project__banner {
    min-height: 0vh;
    max-height: 55vh;
    background-position-y: 7vh;
    align-items: flex-start;
  }
  .project__banner__nav__mobile {
    display: flex;
    width: 100%;
    max-height: 55vh;
    align-items: center;
    justify-content: center;
  }
  .project__banner__theme {
    display: flex;
    width: 100%;
    height: 0vh;
    max-height: 49vh;
    flex-direction: column;
    justify-content: last baseline;
    padding: 270px 0px 0px 15px;
  }
  .project__banner__p1 {
    font-size: 16px;
    font-weight: 700;
    padding-left: 24px;
    margin-bottom: 0px;
  }
  .project__banner__p1__minor__ {
    font-size: 12px;
    font-weight: 300;
    color: var(--orange);
    margin-bottom: 100px;
  }
  .project__banner__p2 {
    width: 90%;
    color: var(--primary);
    padding: 20px 0px 0px 0px;
    font-weight: 200;
    font-size: 12px;
  }
  .project__banner__btn {
    margin-top: 10px;
    margin-bottom: 170px;
  }
}
