.gallery__mid {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--light_gray);
  min-height: 155vh;
  justify-content: center;
  align-items: center;
}
.gallery__mid__header {
  width: 100%;
  height: 7vh;
  display: flex;
  align-items: flex-end;
  margin-top: 30px;
}
.gallery__mid__header p {
  background-color: var(--royal_blue);
  color: var(--white);
  margin-left: 5rem;
  font-weight: 400;
  clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
  padding: 3px 65px 3px 0px;
  font-size: 1.6rem;
  opacity: 0.7;
}
.gallery__first__blk {
  background-color: var(--orange);
  color: var(--white);
  padding: 5px 8px;
  font-weight: 400;
}
.gallery__mid__Img__grid__container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.gallery__mid__Img__container {
  display: flex;
  width: 80vw;
  min-height: 150vh;
  padding: 30px 0px;
}
.gallery__mid__Img__ {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 300px;
  grid-gap: 20px;
}
img {
  width: 100%;
  height: 100%;
  opacity: 0.8;
}
.gallery__left__main,
.gallery__mid__main,
.gallery__right__main {
  display: flex;
  width: 380px;
  height: 620px;
  grid-row: span 2;
  overflow: hidden;
}
/* Media queries */
@media screen and (min-width: 300px) and (max-width: 450px) {
  .gallery__mid {
    min-height: 0vh;
    max-height: 120vh;
    justify-content: center;
    align-items: center;
  }
  .gallery__mid__header {
    height: 5vh;
    margin-top: 50px;
  }
  .gallery__mid__header p {
    margin-left: 0rem;
    padding-left: 0px;
    font-weight: 400;
    font-size: 1rem;
    margin-left: 5px;
    padding: 3px 40px 3px 0px;
  }
  .gallery__mid__Img__container {
    display: flex;
    width: 95vw;
    min-height: 0vh;
    max-height: 90vh;
    padding: 50px 10px 50px 0px;
  }
  .gallery__mid__Img__ {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 120px;
    grid-gap: 10px;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .gallery__left__main,
  .gallery__mid__main,
  .gallery__right__main {
    display: flex;
    width: 120px;
    height: 250px;
    grid-row: span 2;
    overflow: hidden;
  }
}
