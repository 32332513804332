.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 20vh;
}
.links__container {
  display: flex;
  width: 100%;
  height: 15vh;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.links__container nav ul {
  display: flex;
  justify-content: center;
}
.links__container nav ul li {
  padding: 0px 20px;
  text-decoration: none;
  list-style: none;
  font-weight: 400;
  font-size: 1.1rem;
  color: var(--royal_blue);
}
.links__container nav ul li .link {
  text-decoration: none;
  color: var(--royal_blue);
}
.nav__contact__us {
  color: var(--orange);
  border: 3px solid var(--orange);
  text-decoration: none;
  font-weight: 600;
  border-radius: 999px;
  padding: 5px 10px;
  margin-left: 140px;
}
.nav__contact__us .link {
  color: var(--orange);
}
.nav__contact__us .link {
  text-decoration: none;
}
.decorate {
  text-decoration: none;
  color: var(--orange);
  border-top: 3px solid var(--orange);
  border-bottom: 1px dotted var(--orange);
  padding: 5px 0px;
  font-weight: 550;
}
.nav__contact__us.active {
  background-color: var(--orange);
  color: var(--white);
}
@media screen and (max-width: 768px) {
  .navbar {
    display: flex;
    max-width: 100vh;
    width: 100%;
  }
  .links__container {
    display: none;
  }
}
