.community {
  display: flex;
  align-items: center;
  min-height: 94vh;
  width: 100%;
}
.community__Img__desc__container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-height: 90vh;
}
.community__Img__container {
  display: flex;
  width: 50%;
  height: 85vh;
  justify-content: center;
  background: url(../../../Assets/community_kids.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: left;
}
.community__desc__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  min-height: 80vh;
}
.community__header__container {
  display: flex;
  align-items: center;
  height: 10vh;
  width: 100%;
}
.community__header__container h1 {
  font-size: 2.4rem;
  font-weight: 200;
  color: var(--royal_blue);
  border-left: 3px solid var(--orange);
  padding: 0px 10px;
}
.community__desc {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50vh;
}
.community__desc p {
  color: var(--primary);
  font-size: 1.1rem;
  text-align: justify;
  font-weight: 200;
  padding: 20px 5px;
}
/* Media queries */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .community {
    min-height: 0vh;
    max-height: 125vh;
    flex-direction: column;
  }
  .community__Img__desc__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 0vh;
    max-height: 120vh;
  }
  .community__Img__container {
    display: flex;
    width: 100%;
    height: 47vh;
    justify-content: center;
    background-size: 100%;
  }
  .community__desc__container {
    width: 100%;
    min-height: 0vh;
    max-height: 65vh;
    padding: 20px 10px 0px 10px;
    align-items: center;
    justify-content: center;
  }
  .community__header__container {
    height: 7vh;
    width: 100%;
    padding-left: 10px;
  }
  .community__header__container h1 {
    font-size: 1.3rem;
    font-weight: 200;
    color: var(--royal_blue);
    border-left: 3px solid var(--orange);
    padding: 0px 7px;
  }
  .community__desc {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 0vh;
    max-height: 55vh;
    padding-top: 0px;
  }
  .community__desc p {
    color: var(--primary);
    font-size: 14px;
    text-align: justify;
    font-weight: 200;
    padding: 20px 5px;
  }
}
