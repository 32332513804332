.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  width: 12vw;
  margin-left: -50px;
}
.link__logo {
  width: 40px;
  height: 20px;
}
.link__logo img {
  width: 100%;
  height: 100%;
}
/* Media queries */
@media screen and (max-width: 768px) {
  .logo {
    display: none;
  }
}
