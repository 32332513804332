.get__involved__community {
  display: flex;
  width: 100%;
  min-height: 106vh;
  align-items: center;
}
.get__involved__community__container {
  display: flex;
  min-height: 90vh;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 60px;
}
.get__involved__community__Img {
  display: flex;
  width: 47%;
  height: 88vh;
  background: url(../../../Assets/volunteer_bcg.png);
  background-repeat: no-repeat;
  background-size: 100%;
}
.get__involved__community__desc {
  display: flex;
  flex-direction: column;
  width: 48%;
  min-height: 88%;
}
.get__involved__community__header {
  display: flex;
  height: 15vh;
  align-items: center;
  width: 100%;
}
.get__involved__community__header h1 {
  color: var(--royal_blue);
  font-size: 2.4rem;
  font-weight: 200;
  padding-left: 10px;
  border-left: 3px solid var(--orange);
}
.get__involved__community__para {
  display: flex;
  width: 100%;
  min-height: 30vh;
}
.get__involved__community__para p {
  color: var(--primary);
  font-size: 1.1rem;
  text-align: justify;
  padding: 0px 10px 0px 0px;
  font-weight: 200;
}
/* Media queries 300 - 420px */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .get__involved__community {
    display: flex;
    width: 100%;
    min-height: 0vh;
    max-height: 110vh;
    align-items: center;
    margin: 0px 5px;
  }
  .get__involved__community__container {
    display: flex;
    min-height: 0vh;
    max-height: 100vh;
    width: 100%;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
  }
  .get__involved__community__Img {
    display: flex;
    width: 100%;
    height: 47vh;
    background-size: 100%;
    margin: 0px 10px;
  }
  .get__involved__community__desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 0vh;
    max-height: 50vh;
  }
  .get__involved__community__header {
    display: flex;
    height: 3vh;
    align-items: center;
    padding-left: 6px;
    padding-top: 10px;
    width: 100%;
  }
  .get__involved__community__header h1 {
    color: var(--royal_blue);
    font-size: 1.2rem;
    font-weight: 200;
    padding-left: 5px;
    border-left: 3px solid var(--orange);
  }
  .get__involved__community__para {
    display: flex;
    width: 100%;
    margin-top: 15px;
    min-height: 0vh;
    max-height: 45vh;
  }
  .get__involved__community__para p {
    font-size: 14px;
    padding: 0px 8px 10px 8px;
  }
}
