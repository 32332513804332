.project__waste__management {
  display: flex;
  min-height: 90vh;
  align-items: center;
  background-color: var(--custom_blue);
  width: 100%;
}
.project__waste__management__container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-height: 87vh;
}
.project__waste__management__Img {
  display: flex;
  width: 47%;
  height: 85vh;
  background: url(../../../Assets/waste__management.png);
  background-repeat: no-repeat;
  background-size: 100%;
}
.project__waste__management__desc {
  display: flex;
  width: 48%;
  min-height: 85vh;
  flex-direction: column;
  justify-content: center;
}
.project__waste__management__header {
  width: 100%;
  height: 15vh;
  display: flex;
  align-items: center;
}
.project__waste__management__header p {
  color: var(--royal_blue);
  border-left: 3px solid var(--orange);
  padding-left: 10px;
  font-size: 2.4rem;
  font-weight: 200;
}
.project__waste__management__para {
  display: flex;
  min-height: 30vh;
  width: 100%;
  padding-right: 10px;
}
.project__waste__management__para p {
  color: var(--primary);
  font-size: 1.1rem;
  text-align: justify;
  font-weight: 200;
}
/* Media queries 300 - 420px */
@media screen and (min-width: 300px) and (max-width: 420px) {
  .project__waste__management {
    min-height: 0vh;
    max-height: 100vh;
    display: flex;
    align-items: center;
    margin: 5px;
  }
  .project__waste__management__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 0vh;
    max-height: 95vh;
  }
  .project__waste__management__Img {
    width: 100%;
    height: 45vh;
    background-size: 100%;
  }
  .project__waste__management__desc {
    display: flex;
    width: 100%;
    min-height: 0vh;
    max-height: 40vh;
    flex-direction: column;
    justify-content: center;
  }
  .project__waste__management__header {
    width: 100%;
    height: 7vh;
    display: flex;
    align-items: center;
  }
  .project__waste__management__header p {
    padding-left: 5px;
    font-size: 1.2rem;
  }
  .project__waste__management__para {
    display: flex;
    min-height: 15vh;
    width: 100%;
    padding: 0px 3px;
  }
  .project__waste__management__para p {
    font-size: 12px;
  }
}
